<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('trainings.types.edit')">
        <actions
          slot="actions"
          crud-links="trainingTypes"
          controller="TrainingTypes"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :training-type="trainingType"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'training-types-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      headers: { 'Content-Type': 'multipart/form-data' },
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      trainingType: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `trainings/types/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const typeId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(typeId) + '?raw=true')
      } catch (err) {
        // console.log('Error fetching training type data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.trainingType = u.data.data
    },
    async submit (trainingType) {
      this.loading = true
      trainingType.append('_method', 'PUT')

      try {
        await this.$http.post(this.routeBuilder(trainingType.get('id')), trainingType, { headers: this.headers })
      } catch (e) {
        // console.log('Error while updating training type', e)
        this.loading = false
        return
      }

      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.loading = false
    },
  },
}
</script>
